.form-control {
  border-radius:0px !important;
}

  .custom-pointer {
  cursor: pointer;
}
.custom-spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


  .img-container {
  width: 100%;
  height: 100%;
  padding-top: 100%; /* Maintains a square aspect ratio */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  margin-left: 3px !important;
}

/* For mobile view */
@media (max-width: 576px) {
  .btn-block-mobile {
    display: block;
    width: 100%;
  }
}


.main {
  min-height: 400px !important;
}
body {
  font-family: 'Roboto', sans-serif;
}